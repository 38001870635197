/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, utils } from "ethers";
const _abi = [
    {
        inputs: [
            {
                internalType: "address",
                name: "_cfa",
                type: "address",
            },
            {
                internalType: "uint64",
                name: "_minLower",
                type: "uint64",
            },
            {
                internalType: "uint64",
                name: "_minUpper",
                type: "uint64",
            },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        inputs: [
            {
                internalType: "uint64",
                name: "limitGiven",
                type: "uint64",
            },
            {
                internalType: "uint64",
                name: "minLimit",
                type: "uint64",
            },
        ],
        name: "InsufficientLimits",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "uint64",
                name: "expirationTimeGiven",
                type: "uint64",
            },
            {
                internalType: "uint256",
                name: "timeNow",
                type: "uint256",
            },
        ],
        name: "InvalidExpirationTime",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "strategy",
                type: "address",
            },
        ],
        name: "InvalidStrategy",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "caller",
                type: "address",
            },
            {
                internalType: "address",
                name: "expectedCaller",
                type: "address",
            },
        ],
        name: "UnauthorizedCaller",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "superToken",
                type: "address",
            },
        ],
        name: "UnsupportedSuperToken",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "index",
                type: "bytes32",
            },
        ],
        name: "WrapNotRequired",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "uint64",
                name: "lowerLimit",
                type: "uint64",
            },
            {
                internalType: "uint64",
                name: "upperLimit",
                type: "uint64",
            },
        ],
        name: "WrongLimits",
        type: "error",
    },
    {
        inputs: [],
        name: "ZeroAddress",
        type: "error",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "strategy",
                type: "address",
            },
        ],
        name: "AddedApprovedStrategy",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint64",
                name: "lowerLimit",
                type: "uint64",
            },
            {
                indexed: false,
                internalType: "uint64",
                name: "upperLimit",
                type: "uint64",
            },
        ],
        name: "LimitsChanged",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "strategy",
                type: "address",
            },
        ],
        name: "RemovedApprovedStrategy",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "bytes32",
                name: "id",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "wrapAmount",
                type: "uint256",
            },
        ],
        name: "WrapExecuted",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "bytes32",
                name: "id",
                type: "bytes32",
            },
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "superToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "strategy",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "liquidityToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "expiry",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "lowerLimit",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "upperLimit",
                type: "uint256",
            },
        ],
        name: "WrapScheduleCreated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "bytes32",
                name: "id",
                type: "bytes32",
            },
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "superToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "strategy",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "liquidityToken",
                type: "address",
            },
        ],
        name: "WrapScheduleDeleted",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "strategy",
                type: "address",
            },
        ],
        name: "addApprovedStrategy",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "approvedStrategies",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "cfaV1",
        outputs: [
            {
                internalType: "contract IConstantFlowAgreementV1",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "address",
                name: "superToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "liquidityToken",
                type: "address",
            },
        ],
        name: "checkWrap",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "index",
                type: "bytes32",
            },
        ],
        name: "checkWrapByIndex",
        outputs: [
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "superToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "strategy",
                type: "address",
            },
            {
                internalType: "address",
                name: "liquidityToken",
                type: "address",
            },
            {
                internalType: "uint64",
                name: "expiry",
                type: "uint64",
            },
            {
                internalType: "uint64",
                name: "lowerLimit",
                type: "uint64",
            },
            {
                internalType: "uint64",
                name: "upperLimit",
                type: "uint64",
            },
        ],
        name: "createWrapSchedule",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "address",
                name: "superToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "liquidityToken",
                type: "address",
            },
        ],
        name: "deleteWrapSchedule",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "index",
                type: "bytes32",
            },
        ],
        name: "deleteWrapScheduleByIndex",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "address",
                name: "superToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "liquidityToken",
                type: "address",
            },
        ],
        name: "executeWrap",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "index",
                type: "bytes32",
            },
        ],
        name: "executeWrapByIndex",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "address",
                name: "superToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "liquidityToken",
                type: "address",
            },
        ],
        name: "getWrapSchedule",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "user",
                        type: "address",
                    },
                    {
                        internalType: "contract ISuperToken",
                        name: "superToken",
                        type: "address",
                    },
                    {
                        internalType: "contract IStrategy",
                        name: "strategy",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "liquidityToken",
                        type: "address",
                    },
                    {
                        internalType: "uint64",
                        name: "expiry",
                        type: "uint64",
                    },
                    {
                        internalType: "uint64",
                        name: "lowerLimit",
                        type: "uint64",
                    },
                    {
                        internalType: "uint64",
                        name: "upperLimit",
                        type: "uint64",
                    },
                ],
                internalType: "struct IManager.WrapSchedule",
                name: "",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "index",
                type: "bytes32",
            },
        ],
        name: "getWrapScheduleByIndex",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "user",
                        type: "address",
                    },
                    {
                        internalType: "contract ISuperToken",
                        name: "superToken",
                        type: "address",
                    },
                    {
                        internalType: "contract IStrategy",
                        name: "strategy",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "liquidityToken",
                        type: "address",
                    },
                    {
                        internalType: "uint64",
                        name: "expiry",
                        type: "uint64",
                    },
                    {
                        internalType: "uint64",
                        name: "lowerLimit",
                        type: "uint64",
                    },
                    {
                        internalType: "uint64",
                        name: "upperLimit",
                        type: "uint64",
                    },
                ],
                internalType: "struct IManager.WrapSchedule",
                name: "",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "address",
                name: "superToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "liquidityToken",
                type: "address",
            },
        ],
        name: "getWrapScheduleIndex",
        outputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        stateMutability: "pure",
        type: "function",
    },
    {
        inputs: [],
        name: "minLower",
        outputs: [
            {
                internalType: "uint64",
                name: "",
                type: "uint64",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "minUpper",
        outputs: [
            {
                internalType: "uint64",
                name: "",
                type: "uint64",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "strategy",
                type: "address",
            },
        ],
        name: "removeApprovedStrategy",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint64",
                name: "lowerLimit",
                type: "uint64",
            },
            {
                internalType: "uint64",
                name: "upperLimit",
                type: "uint64",
            },
        ],
        name: "setLimits",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];
export class AutoWrapManager__factory {
    static createInterface() {
        return new utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new Contract(address, _abi, signerOrProvider);
    }
}
AutoWrapManager__factory.abi = _abi;
